export const Media = [
  { type: "video", url: require("./video-01.mp4") },
  { type: "image", url: require("./img-01.jpg") },
  { type: "video", url: require("./video-02.mp4") },
  { type: "image", url: require("./img-02.jpg") },
  { type: "video", url: require("./video-03.mp4") },
  { type: "image", url: require("./img-03.jpg") },
  { type: "video", url: require("./video-04.mp4") },
  { type: "image", url: require("./img-04.jpg") },
  { type: "video", url: require("./video-05.mp4") },
  { type: "image", url: require("./img-05.jpg") },
  { type: "video", url: require("./video-06.mp4") },
  { type: "image", url: require("./img-06.jpg") },
  { type: "video", url: require("./video-01.mp4") },
  { type: "image", url: require("./img-01.jpg") },
  { type: "video", url: require("./video-04.mp4") },
];
